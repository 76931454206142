@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  background-image:url('./Image/BG 1.png');
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.App-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(0, 0, 0, 0.27); 
  backdrop-filter: blur(10px);
}
 {/* backdrop-filter: blur(7px); clip-path: polygon(0 0, 100% 0, 100% 70%, 53% 100%, 0 100%);  */}


