.Whatwedo-tagline, .Practices-tagline {
    background: linear-gradient(135deg, #ae8625, #f7ef81, #d2ac47, #edc967);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  
  }

  .Practices-line {
    height: 2px;  width: 60%;
  }

  ul {
    list-style-type: circle;
  }
  ol {
    list-style-type: decimal;
  }