.Lawyers-Line{
    height: 4px;  width: 70%;
}

.lawyer-img {
     /* Set a fixed height */
    border-radius: 50%; /* Makes the image round */
    object-fit: cover;  /* Ensures the image covers the space without distortion */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow */
  }

  .Lawyer-name, .Find-lawyer
    {
        background: linear-gradient(135deg, #ae8625, #f7ef81, #d2ac47, #edc967);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
}