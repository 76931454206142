.Container-AboutUs {
    background-color: black;
}

.abtus-line{
  height: 2px;  width: 30%;
    
}

.History-tagline, .AboutUs-tagline, .Mission-tagline, .Vision-tagline {
  background: linear-gradient(135deg, #ae8625, #f7ef81, #d2ac47, #edc967);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}