.Home-tagline, .Contact-tagline {
     background: linear-gradient(135deg, #ae8625, #f7ef81, #d2ac47, #edc967);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.Newsandupdate-tagline {
    background: linear-gradient(135deg, #ae8625, #f7ef81, #d2ac47, #edc967);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}